import { Spinner } from '@/components/icons/spinner';
import { PropsWithChildren, createContext, useEffect } from 'react';
import { RouterOutput, trpc } from './trpc';
import { TenantLocalStorageSync } from './tenant-local-storage-sync';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function LoadingPage() {
	return (
		<div className="flex h-dvh w-dvw items-center justify-center">
			<Spinner className="h-6 w-6 text-primary/80"></Spinner>
		</div>
	);
}

type User = RouterOutput['usersMe'] | null;
type Language = NonNullable<NonNullable<NonNullable<User>['profile']>['language']>;
export const AuthContext = createContext<User>(null!);

export function AuthProvider({ children }: PropsWithChildren) {
	const navigate = useNavigate();
	const user = trpc.usersMe.useQuery();
	const { i18n, ready } = useTranslation();
	const utils = trpc.useUtils();
	const userLoaded = user.isSuccess && !user.isLoading;
	const userHasProfile = !!user.data?.profile;
	const currentRemoteLanguage = user.data?.profile?.language;

	// Sync language from database to i18n
	useEffect(() => {
		const isEverythingLoaded = ready && userLoaded;
		const isLocalLanguageUnsynced = currentRemoteLanguage !== i18n.language;

		if (isEverythingLoaded && isLocalLanguageUnsynced && currentRemoteLanguage) {
			i18n.changeLanguage(currentRemoteLanguage);
		}
	}, [i18n, ready, currentRemoteLanguage, userLoaded]);

	const updateUser = trpc.usersUpdateProfile.useMutation({
		onMutate: async (changes) => {
			await utils.usersMe.cancel();
			const previousUser = utils.usersMe.getData();

			// Optimistically update language
			if (previousUser) {
				utils.usersMe.setData(undefined, {
					...previousUser,
					profile:
						previousUser.profile && changes.language
							? {
									...previousUser.profile,
									language: (changes.language ?? previousUser.profile?.language)!,
								}
							: null,
				});
			}

			return { previousUser };
		},
		onError: (_error, _variables, context) => {
			utils.usersMe.setData(undefined, context?.previousUser);
		},
	});
	
	// Sync language from i18n to database
	useEffect(() => {
		const isEverythingLoaded = ready && userLoaded && !!i18n.language;

		if (isEverythingLoaded && currentRemoteLanguage !== i18n.language && userHasProfile && updateUser.failureCount === 0) {
			updateUser.mutate({ language: i18n.language as Language });
		}
	}, [currentRemoteLanguage, ready, i18n.language, updateUser, userLoaded, userHasProfile]);

	// Prevents users that do not have a profile, but are logged in, from accessing the app
	useEffect(() => {
		if (user.isLoading) {
			return;
		} else if (!user.data) {
			navigate('/auth/login');
		} else if (!user.data?.profile) {
			navigate('/auth/create-profile');
		}
	}, [user.data, user.isLoading, navigate]);

	return (
		<AuthContext.Provider value={user.data!}>
			{user.isLoading && <LoadingPage />}
			{user.isSuccess && <TenantLocalStorageSync>{children}</TenantLocalStorageSync>}
		</AuthContext.Provider>
	);
}
