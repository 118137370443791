import { Button } from '@/components/ui/button';
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form';
import { useForm } from 'react-hook-form';
import { trpc } from '@/lib/providers/trpc';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { usersVerifyInputSchema } from '@timp/server/src/schemas/users-verify.schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useRef } from 'react';
import { InputOTP, InputOTPGroup, InputOTPSlot } from '@/components/ui/input-otp';
import { REGEXP_ONLY_DIGITS } from 'input-otp';
import { Link } from '@/components/links/Link';

export function VerifyPage() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { id } = useParams();
	const verify = trpc.usersVerify.useMutation({
		onSuccess: (data) => {
			if (data.isNew) {
				navigate('/auth/create-profile', { replace: true });
			} else {
				navigate('/', { replace: true });
			}
		},
	});
	const mutate = verify.mutate;

	const form = useForm<z.infer<typeof usersVerifyInputSchema>>({
		values: {
			otp: '',
			id: id!,
		},
		resolver: zodResolver(usersVerifyInputSchema),
	});

	const mutateCallsRef = useRef(0);

	function tryAutoSubmitOnce(value: string) {
		if (value.length === 6 && verify.failureCount === 0 && mutateCallsRef.current === 0) {
			mutateCallsRef.current += 1;
			mutate({ id: id!, otp: value });
		}
	}

	return (
		<main className="flex flex-col justify-center">
			<div className="flex flex-col gap-2 text-center">
				<h1 className="text-2xl font-semibold tracking-tight">{t('welcome')}</h1>
				<p className="text-sm text-muted-foreground">{t('otp_link_sent')}</p>
			</div>
			<div className="mt-4 flex flex-col justify-center gap-y-4">
				<Form {...form}>
					<form
						onSubmit={form.handleSubmit((data) => verify.mutate({ id: id!, otp: data.otp }))}
						onChange={(ev) => {
							tryAutoSubmitOnce((ev.target as HTMLInputElement).value);
						}}
					>
						<div className="flex flex-col gap-y-4">
							<FormField
								control={form.control}
								name="otp"
								render={({ field }) => (
									<FormItem>
										<FormControl>
											<InputOTP
												autoFocus
												data-testid="otp-input"
												className="flex justify-center"
												pattern={REGEXP_ONLY_DIGITS}
												maxLength={6}
												render={({ slots }) => (
													<InputOTPGroup>
														{slots.map((slot, index) => (
															<InputOTPSlot key={index} {...slot} className="h-12 w-12 sm:h-14 sm:w-14 sm:text-[16px]" />
														))}{' '}
													</InputOTPGroup>
												)}
												{...field}
											/>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
							<Button type="submit" isLoading={verify.isLoading}>
								{t('verify_code')}
							</Button>
							<p className="px-8 text-center text-sm text-muted-foreground">
								{t('did_not_get_code')}{' '}
								<Link to="/auth/login" state={{ from: location.pathname }} className="underline underline-offset-4 hover:text-primary">
									{t('try_again')}
								</Link>
							</p>
						</div>
					</form>
				</Form>
			</div>
		</main>
	);
}
