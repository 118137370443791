import { Button } from '@/components/ui/button';
import { useTranslation } from 'react-i18next';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '@/components/ui/command';
import { CaretSortIcon, PlusIcon } from '@radix-ui/react-icons';
import { CheckIcon } from 'lucide-react';
import { useRef, useState } from 'react';
import { type TRPCError, trpc } from '@/lib/providers/trpc';
import { Spinner } from '../icons/spinner';
import { useTenantIdSafe } from '@/hooks/useTenant';
import { useRole, useUser } from '@/hooks/useUser';
import { SelectManageButton } from './select-manage-button';
import { TypographyMuted } from '../ui/typography';
import { Badge } from '../ui/badge';

type Option = {
	id: string;
	name: string;
};

interface ValueProps {
	value: string | null | undefined;
	disabled?: boolean;
	onChange: (value: string | undefined) => void;
	onOpenAutoFocus?: (event: Event) => void;
	getOptionDescription?: (id: string) => string;
}

interface Props extends ValueProps {
	options: Option[];
	isLoading: boolean;
	error: Error | TRPCError | null;
	footer?: JSX.Element | null;
	disabled?: boolean;
	dataTestId?: string;
	treatNullAsEmptyOption?: boolean;
	getOptionDescription?: (id: string) => string;
}

function SelectSingleBase({
	options,
	value,
	onChange,
	isLoading,
	error,
	footer,
	disabled,
	dataTestId,
	onOpenAutoFocus,
	getOptionDescription,
	treatNullAsEmptyOption = false,
}: Props) {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const selectedOption = options.find((option) => option.id === value);

	// Workaround: ref to the container element, necessary to fix scrolling of the popover inside dialogs
	// https://github.com/radix-ui/primitives/issues/1159#issuecomment-1741282769
	const containerRef = useRef<HTMLDivElement>(null);

	const emptyOptionText = (
		<span className="flex items-center gap-x-1">
			<PlusIcon className="size-3" /> {t('start_from_blank')}
		</span>
	);

	return (
		<div ref={containerRef} className={cn(disabled && 'cursor-not-allowed', 'w-full')}>
			<Popover open={open} onOpenChange={setOpen}>
				<PopoverTrigger asChild>
					<Button
						disabled={disabled}
						type="button"
						variant="outline"
						data-testid={dataTestId}
						className={cn('h-9 w-full justify-between px-2', !value && 'text-blue-500')}
					>
						<span className="flex items-center gap-x-2">
							{!value && treatNullAsEmptyOption ? emptyOptionText : selectedOption?.name}
							{value && selectedOption?.name && getOptionDescription ? (
								<Badge variant="secondary" className="text-gray-500">
									{getOptionDescription(value)}
								</Badge>
							) : null}
						</span>
						<CaretSortIcon className="ml-auto h-4 w-4 shrink-0 opacity-50" />
					</Button>
				</PopoverTrigger>
				<PopoverContent container={containerRef.current} className="p-0" align="start" onOpenAutoFocus={onOpenAutoFocus}>
					<Command
						loop
						filter={(value: string, search: string) => {
							const searchKey = options.find((option) => option.id === value)?.name ?? value;
							return searchKey.toLowerCase().includes(search.toLowerCase()) ? 1 : 0;
						}}
					>
						<CommandInput placeholder={t('search')} className="h-9" />
						<CommandList className="max-h-64 overflow-auto">
							<CommandEmpty>{t('no_results')}</CommandEmpty>
							{isLoading && (
								<CommandGroup>
									<div className="flex h-full w-full items-center justify-center">
										<Spinner size="sm" />
									</div>
								</CommandGroup>
							)}
							{error && (
								<CommandGroup>
									<div className="flex h-full w-full items-center justify-center">
										<span className="text-destructive">{error.message}</span>
									</div>
								</CommandGroup>
							)}
							{treatNullAsEmptyOption && (
								<CommandItem
									value="new"
									onSelect={() => {
										onChange(undefined);
										setOpen(false);
									}}
								>
									<span className="text-blue-500">{emptyOptionText}</span>
									<CheckIcon className={cn('ml-auto h-4 w-4', !value && treatNullAsEmptyOption ? 'opacity-100' : 'opacity-0')} />
								</CommandItem>
							)}

							{options.map((option) => (
								<CommandItem
									value={option.id}
									key={option.id}
									onSelect={(id: string) => {
										// If the selected option is the same as the option being selected, clear the value
										onChange(id === value ? undefined : id);
										setOpen(false);
									}}
									className="flex justify-between"
								>
									{option.name}
									{value !== option.id && getOptionDescription && <TypographyMuted>{getOptionDescription(option.id)}</TypographyMuted>}
									{value === option.id && <CheckIcon className={cn('size-4')} />}
								</CommandItem>
							))}
						</CommandList>
						{footer}
					</Command>
				</PopoverContent>
			</Popover>
		</div>
	);
}

export function SelectSingleDepartment({ value, onChange, disabled, onOpenAutoFocus, getOptionDescription }: ValueProps) {
	const { t } = useTranslation();

	const tenantId = useTenantIdSafe();
	const role = useRole(tenantId);

	const { data, isLoading, error } = trpc.tenantDepartmentsList.useQuery({ tenantId }, { initialData: [] });

	const footer = role === 'ADMIN' ? <SelectManageButton to={`/tenants/${tenantId}/settings/departments`}>{t('manage_departments')}</SelectManageButton> : null;

	return (
		<SelectSingleBase
			dataTestId="select-single-department"
			disabled={disabled}
			options={data}
			isLoading={isLoading}
			error={error}
			value={value}
			onChange={onChange}
			footer={footer}
			onOpenAutoFocus={onOpenAutoFocus}
			getOptionDescription={getOptionDescription}
		/>
	);
}

export function SelectSingleProject({ value, onChange, disabled, onOpenAutoFocus, getOptionDescription }: ValueProps) {
	const { t } = useTranslation();

	const tenantId = useTenantIdSafe();
	const role = useRole(tenantId);
	const canManage = role === 'ADMIN';

	const { data, isLoading, error } = trpc.tenantProjectsList.useQuery({ tenantId }, { initialData: [] });

	const footer = canManage ? <SelectManageButton to={`/tenants/${tenantId}/settings/projects`}>{t('manage_projects')}</SelectManageButton> : null;

	return (
		<SelectSingleBase
			dataTestId="select-single-project"
			disabled={disabled}
			options={data}
			isLoading={isLoading}
			error={error}
			value={value}
			onChange={onChange}
			footer={footer}
			onOpenAutoFocus={onOpenAutoFocus}
			getOptionDescription={getOptionDescription}
		/>
	);
}

export function SelectSingleSupplier({ value, onChange, disabled, onOpenAutoFocus, getOptionDescription }: ValueProps) {
	const { t } = useTranslation();

	const tenantId = useTenantIdSafe();
	const role = useRole(tenantId);
	const canManage = role === 'ADMIN';

	const { data, isLoading, error } = trpc.tenantSuppliersList.useQuery({ tenantId, hideArchived: true });

	const footer = canManage ? <SelectManageButton to={`/tenants/${tenantId}/suppliers`}>{t('manage_suppliers')}</SelectManageButton> : null;

	return (
		<SelectSingleBase
			dataTestId="select-single-supplier"
			disabled={disabled}
			options={data?.rows ?? []}
			isLoading={isLoading}
			error={error}
			value={value}
			onChange={onChange}
			footer={footer}
			onOpenAutoFocus={onOpenAutoFocus}
			getOptionDescription={getOptionDescription}
		/>
	);
}

export function SelectSingleLocation({ value, onChange, disabled, onOpenAutoFocus, getOptionDescription }: ValueProps) {
	const { t } = useTranslation();

	const tenantId = useTenantIdSafe();
	const role = useRole(tenantId);

	const { data, isLoading, error } = trpc.tenantLocationsList.useQuery({ tenantId }, { initialData: [] });

	const footer = role === 'ADMIN' ? <SelectManageButton to={`/tenants/${tenantId}/settings/locations`}>{t('manage_locations')}</SelectManageButton> : null;

	return (
		<SelectSingleBase
			dataTestId="select-single-location"
			disabled={disabled}
			options={data}
			isLoading={isLoading}
			error={error}
			value={value}
			onChange={onChange}
			footer={footer}
			onOpenAutoFocus={onOpenAutoFocus}
			getOptionDescription={getOptionDescription}
		/>
	);
}

export function SelectSingleGroup({ value, onChange, disabled, onOpenAutoFocus }: ValueProps) {
	const { t } = useTranslation();

	const tenantId = useTenantIdSafe();
	const role = useRole(tenantId);

	const { data, isLoading, error } = trpc.tenantGroupsList.useQuery({ tenantId }, { initialData: [] });

	const footer = role === 'ADMIN' ? <SelectManageButton to={`/tenants/${tenantId}/settings/groups`}>{t('manage_groups')}</SelectManageButton> : null;

	return (
		<SelectSingleBase
			dataTestId="select-single-group"
			disabled={disabled}
			options={data}
			isLoading={isLoading}
			error={error}
			value={value}
			onChange={onChange}
			footer={footer}
			onOpenAutoFocus={onOpenAutoFocus}
		/>
	);
}

interface SelectSingleFormTemplateProps extends ValueProps {
	type: 'CASE' | 'INSPECTION';
}

export function SelectSingleFormTemplate({ value, onChange, type, disabled, onOpenAutoFocus }: SelectSingleFormTemplateProps) {
	const { t } = useTranslation();
	const tenantId = useTenantIdSafe();
	const role = useRole(tenantId);
	const { data, isLoading, error } = trpc.tenantFormTemplatesList.useQuery({ tenantId, types: [type] }, { initialData: [] });

	let footer: JSX.Element | null = null;
	if (role === 'ADMIN') {
		footer = <SelectManageButton to={`/tenants/${tenantId}/settings/${type.toLowerCase()}-forms`}>{t('manage_form_templates')}</SelectManageButton>;
	}

	return (
		<SelectSingleBase
			dataTestId="select-single-form-template"
			disabled={disabled}
			options={data}
			isLoading={isLoading}
			error={error}
			value={value}
			onChange={onChange}
			footer={footer}
			onOpenAutoFocus={onOpenAutoFocus}
		/>
	);
}

interface SelectSingleSysadminPublishedFormTemplateProps extends ValueProps {
	type: 'CASE' | 'INSPECTION';
	treatNullAsEmptyOption?: boolean;
	showDrafts?: boolean;
}

export function SelectSingleSysadminFormTemplate({
	value,
	onChange,
	type,
	disabled,
	onOpenAutoFocus,
	treatNullAsEmptyOption = false,
	showDrafts = false,
}: SelectSingleSysadminPublishedFormTemplateProps) {
	const { t } = useTranslation();
	const user = useUser();
	const { data, isLoading, error } = trpc.sysadminFormTemplatesList.useQuery({ types: [type], onlyPublished: !showDrafts }, { initialData: [] });
	let footer: JSX.Element | null = null;
	if (user.isSysAdmin) {
		footer = <SelectManageButton to={`/sysadmin/${type.toLowerCase()}-forms`}>{t('manage_form_templates')}</SelectManageButton>;
	}

	return (
		<SelectSingleBase
			treatNullAsEmptyOption={treatNullAsEmptyOption}
			dataTestId="select-single-sysadmin-form-template"
			disabled={disabled}
			options={data}
			isLoading={isLoading}
			error={error}
			value={value}
			onChange={onChange}
			footer={footer}
			onOpenAutoFocus={onOpenAutoFocus}
		/>
	);
}

interface SelectSingleSysadminPublishedFormTemplateProps extends ValueProps {
	type: 'CASE' | 'INSPECTION';
	treatNullAsEmptyOption?: boolean;
}

export function SelectSingleSysadminPublishedFormTemplate({
	value,
	onChange,
	type,
	disabled,
	onOpenAutoFocus,
	treatNullAsEmptyOption = false,
}: SelectSingleSysadminPublishedFormTemplateProps) {
	const tenantId = useTenantIdSafe();
	const { t } = useTranslation();
	const user = useUser();
	const { data, isLoading, error } = trpc.tenantSysadminFormTemplatesList.useQuery({ tenantId, types: [type] }, { initialData: [] });
	let footer: JSX.Element | null = null;
	if (user.isSysAdmin) {
		footer = <SelectManageButton to={`/sysadmin/${type.toLowerCase()}-forms`}>{t('manage_form_templates')}</SelectManageButton>;
	}

	return (
		<SelectSingleBase
			treatNullAsEmptyOption={treatNullAsEmptyOption}
			dataTestId="select-single-sysadmin-published-form-template"
			disabled={disabled}
			options={data}
			isLoading={isLoading}
			error={error}
			value={value}
			onChange={onChange}
			footer={footer}
			onOpenAutoFocus={onOpenAutoFocus}
		/>
	);
}

export function SelectSingleDocumentCategory({ value, onChange, disabled, onOpenAutoFocus }: ValueProps) {
	const { t } = useTranslation();

	const tenantId = useTenantIdSafe();
	const role = useRole(tenantId);

	const { data, isLoading, error } = trpc.tenantDocumentCategoriesList.useQuery({ tenantId }, { initialData: [] });

	const footer =
		role === 'ADMIN' ? (
			<SelectManageButton to={`/tenants/${tenantId}/settings/document-categories`}>{t('manage_document_categories')}</SelectManageButton>
		) : null;

	return (
		<SelectSingleBase
			dataTestId="select-single-document-category"
			disabled={disabled}
			options={data}
			isLoading={isLoading}
			error={error}
			value={value}
			onChange={onChange}
			footer={footer}
			onOpenAutoFocus={onOpenAutoFocus}
		/>
	);
}

export function SelectSingleEquipment({ value, onChange, disabled, onOpenAutoFocus }: ValueProps) {
	const { t } = useTranslation();

	const tenantId = useTenantIdSafe();
	const role = useRole(tenantId);

	const { data, isLoading, error } = trpc.tenantEquipmentList.useQuery({ tenantId }, { initialData: [] });

	const footer = role === 'ADMIN' ? <SelectManageButton to={`/tenants/${tenantId}/settings/equipment`}>{t('manage_equipment')}</SelectManageButton> : null;

	return (
		<SelectSingleBase
			dataTestId="select-single-equipment"
			disabled={disabled}
			options={data}
			isLoading={isLoading}
			error={error}
			value={value}
			onChange={onChange}
			footer={footer}
			onOpenAutoFocus={onOpenAutoFocus}
		/>
	);
}
