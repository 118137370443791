import { Button } from '@/components/ui/button';
import { FormControl } from '@/components/ui/form';
import { useTranslation } from 'react-i18next';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from '@/components/ui/command';
import { CaretSortIcon } from '@radix-ui/react-icons';
import { CheckIcon } from 'lucide-react';
import { CollapsedBadges } from '@/components/badges/collapsed-badges';
import { TRPCError, trpc } from '@/lib/providers/trpc';
import { SelectManageButton } from './select-manage-button';
import { Spinner } from '../icons/spinner';
import { useTenantIdSafe } from '@/hooks/useTenant';
import { useRole, useUser } from '@/hooks/useUser';
import { useRef } from 'react';
import { Badge } from '../ui/badge';
import { CommandList } from 'cmdk';

type Option = {
	id: string;
	name?: string;
	isNew?: boolean;
	disabled?: boolean;
};
type OptionWithName = {
	id: string;
	name: string;
	disabled?: boolean;
	isNew?: boolean;
};

interface ValueProps {
	values: Option[];
	className?: string;
	disabled?: boolean;
	onChange: (values: Option[]) => void;
}

interface Props extends ValueProps {
	options: Option[];
	isLoading?: boolean;
	error?: Error | TRPCError | null;
	footer?: JSX.Element | null;
	onOpenAutoFocus?: (event: Event) => void;
}

function SelectMultipleBase({ values, onChange, options, isLoading, error, footer, disabled, className, onOpenAutoFocus }: Props) {
	const { t } = useTranslation();
	const selectedOptions = values.map((opt) => options.find((option) => option.id === opt.id)! as OptionWithName);

	// Workaround: ref to the container element, necessary to fix scrolling of the popover inside dialogs
	// https://github.com/radix-ui/primitives/issues/1159#issuecomment-1741282769
	const containerRef = useRef<HTMLDivElement>(null);

	// Filter options to show only non-archived or selected archived options
	const filteredOptions = options.filter((option) => !option.disabled || values.some((v) => v.id === option.id));

	// New: Get selectable options (non-disabled or already selected)
	const selectableOptions = options.filter((option) => !option.disabled || values.some((v) => v.id === option.id));

	return (
		<div ref={containerRef} className={cn(disabled && '!cursor-not-allowed', 'w-full', className)}>
			<Popover>
				<PopoverTrigger asChild>
					<FormControl>
						<Button
							type="button"
							variant="outline"
							role="combobox"
							className={cn('h-9 w-full justify-between px-2', !values && 'text-muted-foreground')}
							data-testid="select-multiple-trigger"
							disabled={disabled}
						>
							<CollapsedBadges variant="secondary" items={selectedOptions} collapseAt={1} />
							<CaretSortIcon className="ml-auto h-4 w-4 shrink-0 opacity-50" />
						</Button>
					</FormControl>
				</PopoverTrigger>
				<PopoverContent container={containerRef.current} className="p-0" align="start" onOpenAutoFocus={onOpenAutoFocus}>
					<Command
						loop
						filter={(value: string, search: string) => {
							// keep select toggle at all times
							if (value === 'select-all') {
								return 1;
							}
							const searchKey = options.find((opti) => opti.id === value)?.name ?? value;
							return searchKey.toLowerCase().includes(search.toLowerCase()) ? 1 : 0;
						}}
					>
						<CommandInput placeholder={t('search')} className="h-9" />
						<CommandList className="max-h-64 overflow-auto">
							<CommandEmpty>{t('no_results')}</CommandEmpty>
							{isLoading && (
								<CommandGroup>
									<div className="flex h-full w-full items-center justify-center">
										<Spinner size="sm" />
									</div>
								</CommandGroup>
							)}
							{error && (
								<CommandGroup>
									<div className="flex h-full w-full items-center justify-center">
										<span className="text-destructive">{error.message}</span>
									</div>
								</CommandGroup>
							)}
							{options.length > 0 && (
								<CommandItem
									key="select-all"
									value="select-all"
									type="button"
									className="justify-start p-2 text-muted-foreground"
									data-testid="select-multiple-select-all-toggle"
									onSelect={() => {
										if (values.length === selectableOptions.length) {
											onChange([]);
										} else {
											onChange(selectableOptions);
										}
									}}
								>
									{values.length === selectableOptions.length ? t('deselect_all') : t('select_all')}
								</CommandItem>
							)}
							{filteredOptions.map((option) => (
								<CommandItem
									asChild
									value={option.id}
									key={option.id}
									onSelect={() => {
										const current = new Map(values.map((val) => [val.id, val]));
										if (current.has(option.id)) {
											current.delete(option.id);
										} else {
											current.set(option.id, option);
										}
										onChange(Array.from(current.values()));
									}}
								>
									{option.name || t('notAvailable')}
									{option.isNew && (
										<Badge className="ml-1 border text-gray-500" variant="secondary">
											{t('new')}
										</Badge>
									)}
									{option.disabled && (
										<Badge className="ml-1 border text-gray-500" variant="secondary">
											{t('disabled')}
										</Badge>
									)}
									<CheckIcon className={cn('ml-auto h-4 w-4', values.map((v) => v.id).includes(option.id) ? 'opacity-100' : 'opacity-0')} />
								</CommandItem>
							))}
						</CommandList>
						{footer}
					</Command>
				</PopoverContent>
			</Popover>
		</div>
	);
}

export function SelectMultipleDepartments({ values, onChange, disabled, className }: ValueProps) {
	const { t } = useTranslation();
	const tenantId = useTenantIdSafe();
	const role = useRole(tenantId);

	const { data = [], isLoading, error } = trpc.tenantDepartmentsList.useQuery({ tenantId }, { initialData: [] });

	const allOptions = [
		...data.map((dept) => ({ id: dept.id, name: dept.name, isNew: false })),
		...values.filter((val) => !data.some((dept) => dept.id === val.id)).map((val) => ({ ...val, isNew: true })),
	];

	const footer = role === 'ADMIN' ? <SelectManageButton to={`/tenants/${tenantId}/settings/departments`}>{t('manage_departments')}</SelectManageButton> : null;

	return (
		<SelectMultipleBase
			className={className}
			disabled={disabled}
			options={allOptions}
			isLoading={isLoading}
			error={error}
			values={values}
			onChange={(selectedIds) => {
				const newValues = selectedIds.map((selected) => allOptions.find((opt) => opt.id === selected.id)!).filter((v) => v);
				onChange(newValues);
			}}
			footer={footer}
		/>
	);
}

export function SelectMultipleProjects({ values, onChange, disabled, className }: ValueProps) {
	const { t } = useTranslation();
	const tenantId = useTenantIdSafe();
	const role = useRole(tenantId);

	const { data = [], isLoading, error } = trpc.tenantProjectsList.useQuery({ tenantId }, { initialData: [] });

	const allOptions = [
		...data.map((dept) => ({ id: dept.id, name: dept.name, isNew: false })),
		...values.filter((val) => !data.some((dept) => dept.id === val.id)).map((val) => ({ ...val, isNew: true })),
	];

	const footer = role === 'ADMIN' ? <SelectManageButton to={`/tenants/${tenantId}/settings/projects`}>{t('manage_projects')}</SelectManageButton> : null;

	return (
		<SelectMultipleBase
			className={className}
			disabled={disabled}
			options={allOptions}
			isLoading={isLoading}
			error={error}
			values={values}
			onChange={(selectedIds) => {
				const newValues = selectedIds.map((selected) => allOptions.find((opt) => opt.id === selected.id)!).filter((v) => v);
				onChange(newValues);
			}}
			footer={footer}
		/>
	);
}

export function SelectMultipleLocations({ values, onChange, disabled, className }: ValueProps) {
	const { t } = useTranslation();
	const tenantId = useTenantIdSafe();
	const role = useRole(tenantId);

	const { data = [], isLoading, error } = trpc.tenantLocationsList.useQuery({ tenantId }, { initialData: [] });

	const allOptions = [
		...data.map((dept) => ({ id: dept.id, name: dept.name, isNew: false })),
		...values.filter((val) => !data.some((dept) => dept.id === val.id)).map((val) => ({ ...val, isNew: true })),
	];

	const footer = role === 'ADMIN' ? <SelectManageButton to={`/tenants/${tenantId}/settings/locations`}>{t('manage_locations')}</SelectManageButton> : null;

	return (
		<SelectMultipleBase
			className={className}
			disabled={disabled}
			options={allOptions}
			isLoading={isLoading}
			error={error}
			values={values}
			onChange={(selectedIds) => {
				const newValues = selectedIds.map((selected) => allOptions.find((opt) => opt.id === selected.id)!).filter((v) => v);
				onChange(newValues);
			}}
			footer={footer}
		/>
	);
}

export function SelectMultipleGroups({ values, onChange, disabled, className }: ValueProps) {
	const { t } = useTranslation();
	const tenantId = useTenantIdSafe();
	const role = useRole(tenantId);

	const { data = [], isLoading, error } = trpc.tenantGroupsList.useQuery({ tenantId }, { initialData: [] });

	const allOptions = [
		...data.map((dept) => ({ id: dept.id, name: dept.name, isNew: false })),
		...values.filter((val) => !data.some((dept) => dept.id === val.id)).map((val) => ({ ...val, isNew: true })),
	];

	const footer = role === 'ADMIN' ? <SelectManageButton to={`/tenants/${tenantId}/settings/groups`}>{t('manage_groups')}</SelectManageButton> : null;

	return (
		<SelectMultipleBase
			className={className}
			disabled={disabled}
			options={allOptions}
			isLoading={isLoading}
			error={error}
			values={values}
			onChange={(selectedIds) => {
				const newValues = selectedIds.map((selected) => allOptions.find((opt) => opt.id === selected.id)!).filter((v) => v);
				onChange(newValues);
			}}
			footer={footer}
		/>
	);
}

export function SelectMultipleSuppliers({ values, onChange, disabled, className }: ValueProps) {
	const { t } = useTranslation();

	const tenantId = useTenantIdSafe();
	const role = useRole(tenantId);
	const canManage = role === 'ADMIN';

	const { data, isLoading, error } = trpc.tenantSuppliersList.useQuery({ tenantId });

	const footer = canManage ? <SelectManageButton to={`/tenants/${tenantId}/suppliers`}>{t('manage_suppliers')}</SelectManageButton> : null;

	return (
		<SelectMultipleBase
			className={className}
			disabled={disabled}
			options={data?.rows.map((supplier) => ({ id: supplier.id, name: supplier.name, disabled: supplier.isArchived })) ?? []}
			isLoading={isLoading}
			error={error}
			values={values}
			onChange={onChange}
			footer={footer}
		/>
	);
}

export function SelectMultipleDocumentCategories({ values, onChange, disabled, className }: ValueProps) {
	const { t } = useTranslation();

	const tenantId = useTenantIdSafe();
	const role = useRole(tenantId);

	const { data = [], isLoading, error } = trpc.tenantDocumentCategoriesList.useQuery({ tenantId }, { initialData: [] });

	const footer =
		role === 'ADMIN' ? (
			<SelectManageButton to={`/tenants/${tenantId}/settings/document-categories`}>{t('manage_document_categories')}</SelectManageButton>
		) : null;

	return (
		<SelectMultipleBase
			className={className}
			disabled={disabled}
			options={data}
			isLoading={isLoading}
			error={error}
			values={values}
			onChange={onChange}
			footer={footer}
		/>
	);
}

export function SelectMultipleAutomaticCases({ onChange, values, disabled, className }: ValueProps) {
	const { t } = useTranslation();

	const tenantId = useTenantIdSafe();
	const role = useRole(tenantId);

	const { data, isLoading, error } = trpc.tenantAutomaticCasesList.useQuery({
		tenantId,
	});

	const footer =
		role === 'ADMIN' ? <SelectManageButton to={`/tenants/${tenantId}/settings/automatic-cases`}>{t('manage_automatic_cases')}</SelectManageButton> : null;

	return (
		<SelectMultipleBase
			className={className}
			disabled={disabled}
			options={
				data?.rows?.map((opt) => ({
					name: opt.title,
					id: opt.id,
				})) ?? []
			}
			isLoading={isLoading}
			error={error}
			values={values}
			onChange={onChange}
			footer={footer}
		/>
	);
}

export function SelectMultipleTenants({ values, onChange, disabled, className }: ValueProps) {
	const user = useUser();

	const tenants = user.tenants;

	const allOptions = [
		...tenants.map((tenant) => ({ id: tenant.tenantId, name: tenant.name, isNew: false })),
		...values.filter((val) => !tenants.some((tenant) => tenant.tenantId === val.id)).map((val) => ({ ...val, isNew: true })),
	];

	return (
		<SelectMultipleBase
			className={className}
			disabled={disabled}
			options={allOptions}
			values={values}
			onChange={(selectedIds) => {
				const newValues = selectedIds.map((selected) => allOptions.find((opt) => opt.id === selected.id)!).filter((v) => v);
				onChange(newValues);
			}}
		/>
	);
}