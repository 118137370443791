import { FieldValues, UseFormReturn, useFieldArray } from 'react-hook-form';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '../ui/form';
import { cn, narrowForm } from '@/lib/utils';
import { useTranslation } from 'react-i18next';
import { TypographySmall } from '../ui/typography';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../ui/accordion';
import { DeviationSeverityIconLabel } from '../labels/deviation-severity-icon-label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select';
import { Textarea } from '../ui/textarea';
import { Button } from '../ui/button';
import { Trash2Icon } from 'lucide-react';
import { useEffect } from 'react';

interface FormDeviations {
	deviations: {
		description: string;
		severity: 'LOW' | 'MEDIUM' | 'HIGH';
	}[];
}
interface Props<T extends FieldValues> {
	form: UseFormReturn<T & FormDeviations>;
	focusedDeviation?: string;
	setFocusedDeviation: (val?: string) => void;
}

export function PartialFormDeviationsList<T extends FieldValues>({ form: _form, setFocusedDeviation, focusedDeviation }: Props<T>) {
	const form = narrowForm<FormDeviations>(_form);

	const deviations = useFieldArray({
		control: form.control,
		name: 'deviations',
	});

	const errors = form.formState.errors;
	// Focus on the first deviation with an error when the form is submitted
	useEffect(() => {
		if (form.formState.isSubmitting) {
			for (const key in errors) {
				if (key === 'deviations') {
					const deviationWithErrorIdx = errors?.deviations?.findIndex?.((dv) => !!dv);
					if (typeof deviationWithErrorIdx === 'number') {
						setFocusedDeviation(deviationWithErrorIdx.toString());
					}
				}
			}
		}
	}, [setFocusedDeviation, errors, form.formState.isSubmitting]);

	const { t } = useTranslation();

	return (
		<div className="flex flex-col gap-y-2">
			<div className="flex items-center justify-between">
				<FormLabel required={false}>{t('deviations')}</FormLabel>
				<button
					type="button"
					className="mr-3"
					onClick={() => {
						deviations.append({
							description: '',
							severity: 'LOW',
						});
						setFocusedDeviation?.(deviations.fields.length.toString());
					}}
				>
					<TypographySmall className="text-blue-600 hover:underline">+ {t('new_deviation')}</TypographySmall>
				</button>
			</div>

			<Accordion type="single" collapsible value={focusedDeviation} onValueChange={(val) => setFocusedDeviation?.(val)}>
				<div className="w-full space-y-0.5">
					{deviations.fields.map((deviation, index) => (
						<AccordionItem key={deviation.id} value={index.toString()} className={cn('rounded-md border px-4 data-[state=open]:bg-muted')}>
							<AccordionTrigger className="group" type="button">
								<DeviationSeverityIconLabel severity={deviation.severity} />
							</AccordionTrigger>
							<AccordionContent>
								<div className="flex flex-col gap-y-4">
									<FormField
										control={form.control}
										name={`deviations.${index}.severity`}
										render={({ field }) => (
											<FormItem>
												<FormLabel required> {t('severity')}</FormLabel>
												<Select
													onValueChange={(val) => {
														field.onChange(val);
														deviations.move(index, index); // Workaround that forces a re-render of the form
													}}
													value={field.value}
												>
													<FormControl>
														<SelectTrigger className="bg-primary-foreground">
															<SelectValue />
														</SelectTrigger>
													</FormControl>
													<SelectContent>
														<SelectItem value="LOW">{t('LOW')}</SelectItem>
														<SelectItem value="MEDIUM">{t('MEDIUM')}</SelectItem>
														<SelectItem value="HIGH">{t('HIGH')}</SelectItem>
													</SelectContent>
												</Select>
												<FormMessage />
											</FormItem>
										)}
									/>

									<FormField
										control={form.control}
										name={`deviations.${index}.description`}
										render={({ field }) => (
											<FormItem>
												<FormLabel required className="text-muted-foreground">
													{t('reason')}
												</FormLabel>
												<FormControl>
													<Textarea rows={8} className="bg-primary-foreground" {...field}></Textarea>
												</FormControl>
												<FormMessage />
											</FormItem>
										)}
									/>
									<Button type="button" variant="link" className="h-6 p-0" onClick={() => deviations.remove(index)}>
										<Trash2Icon className="size-4" />
										{t('delete_deviation')}
									</Button>
								</div>
							</AccordionContent>
						</AccordionItem>
					))}
				</div>
			</Accordion>
		</div>
	);
}
