import * as React from 'react';
import { DashIcon } from '@radix-ui/react-icons';
import { OTPInput, OTPInputProps, SlotProps } from 'input-otp';

import { cn } from '@/lib/utils';

const InputOTP = React.forwardRef<OTPInputProps, Omit<OTPInputProps, 'ref'>>(({ className, ...props }, ref) => (
	<OTPInput ref={ref} containerClassName={cn('flex items-center gap-2', className)} {...props} />
));
InputOTP.displayName = 'InputOTP';

const InputOTPGroup = React.forwardRef<React.ElementRef<'div'>, React.ComponentPropsWithoutRef<'div'>>(({ className, ...props }, ref) => (
	<div ref={ref} className={cn('flex items-center', className)} {...props} />
));
InputOTPGroup.displayName = 'InputOTPGroup';

const InputOTPSlot = React.forwardRef<React.ElementRef<'div'>, SlotProps & React.ComponentPropsWithoutRef<'div'>>(
	({ char, hasFakeCaret, isActive, className, ...props }, ref) => {
		return (
			<div
				ref={ref}
				className={cn(
					'relative flex h-9 w-9 items-center justify-center border-y border-r border-input text-sm shadow-sm transition-all first:rounded-l-md first:border-l last:rounded-r-md',
					isActive && 'z-10 ring-1 ring-ring',
					className,
				)}
				{...props}
			>
				{char}
				{hasFakeCaret && (
					<div className="pointer-events-none absolute inset-0 flex items-center justify-center">
						<div className="h-4 w-px animate-caret-blink bg-foreground duration-1000" />
					</div>
				)}
			</div>
		);
	},
);
InputOTPSlot.displayName = 'InputOTPSlot';

const InputOTPSeparator = React.forwardRef<React.ElementRef<'div'>, React.ComponentPropsWithoutRef<'div'>>(({ ...props }, ref) => (
	<div ref={ref} role="separator" {...props}>
		<DashIcon />
	</div>
));
InputOTPSeparator.displayName = 'InputOTPSeparator';

export { InputOTP, InputOTPGroup, InputOTPSlot, InputOTPSeparator };
