import { Button } from '@/components/ui/button';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { useUser } from '@/hooks/useUser';
import { trpc } from '@/lib/providers/trpc';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usersUpdateProfileInputSchema } from '@timp/server/src/schemas/users-update-profile.schema';
import { z } from 'zod';
import { toast } from 'sonner';
import { Page, PageContent, PageTitle, PageToolbar } from '@/components/layouts/page';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { SelectMultipleTenants } from '@/components/selects/select-multiple';
import { Checkbox } from '@/components/ui/checkbox';

type Language = z.infer<typeof usersUpdateProfileInputSchema>['language'];
export function ProfilePage() {
	const { t, i18n } = useTranslation();

	const user = useUser();

	const { mutate: mutateProfile, isLoading: profileIsLoading } = trpc.usersUpdateProfile.useMutation({
		async onSuccess() {
			toast.success(t('profile_update_success'), {
				action: {
					label: t('close'),
					onClick: () => void 0,
				},
			});
		},
	});

	const form = useForm<z.infer<typeof usersUpdateProfileInputSchema>>({
		resolver: zodResolver(usersUpdateProfileInputSchema),
		values: user?.profile
			? {
					firstName: user.profile.firstName,
					lastName: user.profile.lastName,
					language: user.profile.language ?? (i18n.language as Language),
					weeklyTasksEmails: {
						enabled: user.profile.enableWeeklyTasksEmails,
						tenantIds: user.tenants.filter((tenant) => tenant.enableWeeklyTasksEmails).map((tenant) => tenant.tenantId),
					},
				}
			: undefined,
	});

	return (
		<Page size="container">
			<PageToolbar>
				<PageTitle backLink>{t('profile')}</PageTitle>
			</PageToolbar>
			<PageContent scroll>
				<Form {...form}>
					<form onSubmit={form.handleSubmit((data) => mutateProfile(data))} className="space-y-8">
						<FormField
							control={form.control}
							name="firstName"
							render={({ field }) => (
								<FormItem>
									<FormLabel required>{t('first_name')}</FormLabel>
									<FormControl>
										<Input placeholder="Ola" {...field} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="lastName"
							render={({ field }) => (
								<FormItem>
									<FormLabel required>{t('last_name')}</FormLabel>
									<FormControl>
										<FormControl>
											<Input placeholder="Nordmann" {...field} />
										</FormControl>
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="language"
							render={({ field }) => (
								<FormItem>
									<FormLabel required>{t('language')}</FormLabel>
									<Select onValueChange={field.onChange} value={field.value}>
										<FormControl>
											<SelectTrigger>
												<SelectValue />
											</SelectTrigger>
										</FormControl>
										<SelectContent>
											<SelectItem value="en">{t('language_english')}</SelectItem>
											<SelectItem value="no">{t('language_norwegian')}</SelectItem>
										</SelectContent>
									</Select>
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="weeklyTasksEmails"
							render={({ field }) => (
								<FormItem className="flex flex-col gap-y-3 space-y-0">
									<div className="flex items-center gap-x-2 space-y-0">
										<FormControl>
											<Checkbox
												onCheckedChange={(checked) => {
													field.onChange({
														enabled: !!checked,
														tenantIds: field.value?.tenantIds ?? [],
													});
												}}
												name={field.name}
												checked={field.value?.enabled ?? false}
											/>
										</FormControl>
										<FormLabel required={false}>{t('enable_weekly_tasks_emails')}</FormLabel>
									</div>
									{field.value?.enabled && (
										<SelectMultipleTenants
											values={
												field?.value?.tenantIds?.map((id) => ({
													id,
												})) ?? []
											}
											onChange={(options) =>
												field.onChange({
													enabled: true,
													tenantIds: options.map((v) => v.id),
												})
											}
										/>
									)}
									<FormDescription>{t('weekly_tasks_emails_description')}</FormDescription>
									<FormMessage />
								</FormItem>
							)}
						/>

						<div className="flex justify-end">
							<Button type="submit" isLoading={profileIsLoading}>
								{t('update_profile')}
							</Button>
						</div>
					</form>
				</Form>
			</PageContent>
		</Page>
	);
}
