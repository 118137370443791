import { Outlet } from 'react-router-dom';
import TimpLogo from '../icons/timp-logo';
import { GearIcon } from '@radix-ui/react-icons';
import { LanguageSettingsMenu, ThemeSettingsMenu } from '../dropdown-menus/menu-settings-primitives';
import { Menubar, MenubarContent, MenubarMenu, MenubarTrigger } from '../ui/menubar';

export function AuthLayout(): JSX.Element {
	return (
		<div className="flex h-full w-full bg-muted">
			<div className="hidden h-full w-full items-center justify-center bg-primary text-lg font-medium text-white dark:bg-background lg:flex">
				<TimpLogo className="h-20 w-20" />
				TIMP
			</div>

			<div className="relative flex h-full w-full justify-center">
				<div className="absolute right-8 top-8">
					<Menubar className="flex h-8 w-8 items-center justify-center rounded-full">
						<MenubarMenu>
							<MenubarTrigger className="flex size-10 cursor-pointer items-center justify-center bg-primary p-3 focus:bg-foreground focus:text-primary-foreground data-[state=open]:bg-primary data-[state=open]:text-primary-foreground">
								<GearIcon className="h-5 w-5 text-background dark:text-background" />
							</MenubarTrigger>
							<MenubarContent>
								<LanguageSettingsMenu />
								<ThemeSettingsMenu />
							</MenubarContent>
						</MenubarMenu>
					</Menubar>
				</div>

				<div className="flex w-full flex-col items-center justify-center px-2">
					<div className="mx-auto flex w-full max-w-sm flex-col justify-center space-y-6">
						<Outlet />
					</div>
				</div>
			</div>
		</div>
	);
}
