import { Separator } from '../ui/separator';
import { ExternalLinkButton } from '../buttons/link-button';
import { GitCompareArrowsIcon } from 'lucide-react';

interface Props {
	children: string;
	to: string;
}
export function SelectManageButton({ children, to }: Props): JSX.Element | null {
	return (
		<>
			<Separator />
			<div className="flex w-full justify-center p-1">
				<ExternalLinkButton tabIndex={-1} target="_blank" href={to} variant="link" className="w-full items-center gap-x-1 text-muted-foreground">
					<GitCompareArrowsIcon className="size-4"></GitCompareArrowsIcon>
					{children}
				</ExternalLinkButton>
			</div>
		</>
	);
}
