export function useCurrentApp() {
	const pathname = window.location.pathname;
	const isSupplierApp = pathname.startsWith('/supplier/');
	const isInspectorApp = pathname.startsWith('/inspector/');
	const isMainApp = !isSupplierApp && !isInspectorApp;

	return {
		isSupplierApp,
		isInspectorApp,
		isMainApp,
	};
}
