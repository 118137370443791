import React, { useState } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import { Button } from '../ui/button';
import { CaretSortIcon, GearIcon } from '@radix-ui/react-icons';
import { Command, CommandGroup, CommandInput, CommandItem } from '../ui/command';
import { CheckIcon } from 'lucide-react';
import { trpc } from '@/lib/providers/trpc';
import { cn } from '@/lib/utils';
import { useTranslation } from 'react-i18next';
import { Spinner } from '../icons/spinner';
import { useUser } from '@/hooks/useUser';
import { useTenantId } from '@/hooks/useTenant';
import { useNavigate } from 'react-router-dom';

interface Props {
	hideSysAdmin?: boolean;
}

export function TenantMenu({ hideSysAdmin }: Props) {
	const { data, isLoading } = trpc.tenantsList.useQuery();
	const tenants = data ?? [];

	const user = useUser();
	const currentTenantId = useTenantId();

	const { t } = useTranslation();
	const navigate = useNavigate();

	const [open, setOpen] = useState(false);

	if (hideSysAdmin && tenants.length < 2) {
		return null;
	}

	// Show demo tenants last
	const tenantsDemoLast = [...tenants].sort((a, b) => (a.isDemo ? 1 : b.isDemo ? -1 : 0));

	return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger asChild>
				<Button
					variant="ghost"
					role="combobox"
					data-testid="tenant-menu"
					aria-expanded={open}
					className="h-8 justify-between bg-primary font-semibold text-background hover:bg-primary hover:text-background dark:bg-background dark:text-primary"
				>
					<span className="truncate">{currentTenantId ? tenants.find((tenant) => tenant.id === currentTenantId)?.name : t('select_tenant')}</span>
					<CaretSortIcon className="h-4 w-4 shrink-0" />
				</Button>
			</PopoverTrigger>

			<PopoverContent className="w-[200px] p-0">
				<Command
					filter={(value: string, search: string) => {
						const tenant = tenants.find((tenant) => tenant.id === value);
						const searchKey = tenant?.name ?? value;
						return searchKey.toLowerCase().includes(search.toLowerCase()) ? 1 : 0;
					}}
				>
					<CommandInput placeholder={t('search_tenant')} className="h-9" />
					{isLoading && (
						<CommandItem>
							<div className="flex justify-center">
								<Spinner />
							</div>
						</CommandItem>
					)}
					{!isLoading && !data?.length && (
						<CommandItem>
							<span className="px-1 text-center">{t('no_tenants_matching_search')}</span>
						</CommandItem>
					)}

					{!hideSysAdmin && user.isSysAdmin && (
						<CommandGroup>
							<CommandItem
								className="cursor-pointer"
								onSelect={() => {
									setOpen(false);
									navigate(`/sysadmin`);
								}}
							>
								<GearIcon className="mr-2" /> {t('admin_panel_title')}
							</CommandItem>
						</CommandGroup>
					)}
					<CommandGroup>
						<div className="max-h-96 overflow-auto">
							{tenantsDemoLast.map((tenant) => (
								<CommandItem
									key={tenant.id}
									value={tenant.id}
									className="cursor-pointer"
									onSelect={(tenantId: string) => {
										setOpen(false);
										navigate(`/tenants/${tenantId}`);
									}}
								>
									<div className="flex items-center overflow-hidden">
										<CheckIcon className={cn('mr-2 h-4 w-4 shrink-0', tenant.id === currentTenantId ? 'opacity-100' : 'opacity-0')} />
										<span className="truncate">{tenant.name}</span>
									</div>
								</CommandItem>
							))}
						</div>
						{isLoading && (
							<CommandItem>
								<Spinner className="mx-auto" />
							</CommandItem>
						)}
					</CommandGroup>
				</Command>
			</PopoverContent>
		</Popover>
	);
}
